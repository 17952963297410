.turquoiseTab a {
    color: #03C8A8 !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    background-color: transparent;
    border-color: transparent;
    
}

.turquoiseTab a.active {
    border-color: #03C8A8 #03C8A8 #EFEFF4 !important;
    background: transparent !important;
}

.turquoiseTab a:hover {
    border-color: transparent !important;
}

.turquoiseTab a.active:hover {
    border-color: #03C8A8 #03C8A8 #EFEFF4 !important;
}

.nav-tabs {
    border-bottom: 1px solid #03C8A8 !important;
}

