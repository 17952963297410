p {
    padding: 0px !important;
}

.no-pad {
    padding: 0px !important;
}

.App {
    text-align: center;
}