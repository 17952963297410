:root {
    --width: 60px;
    --bgcolor: #cccc;
    --oncolor: blue;
    --innerpadding: 4px;
    --borderradius: 34px;
    --buttonradius: 50%;
    --buttoncolor: white;
}

.switch {
    margin: 0;
    position: relative;
    display: inline-block;
    background: var(--buttoncolor);
    width: var(--width);
    height: calc(var(--width)/2 + var(--innerpadding));
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bgcolor);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: calc(var(--width)/2 - var(--innerpadding));
    width: calc(var(--width)/2 - var(--innerpadding));
    left: var(--innerpadding);
    bottom: var(--innerpadding);
    background-color: var(--buttoncolor);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: var(--oncolor);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--oncolor);
}

input:checked+.slider:before {
    -webkit-transform: translateX(calc(var(--width)/2 - var(--innerpadding)));
    -ms-transform: translateX(calc(var(--width)/2 - var(--innerpadding)));
    transform: translateX(calc(var(--width)/2 - var(--innerpadding)));
}

/* Rounded sliders */

.slider.round {
    border-radius: var(--borderradius);
}

.slider.round:before {
    border-radius: var(--buttonradius);
}