.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

/* fluid 3 columns */
@media only screen and (min-width: 768px) {
  .grid-sizer, .grid-item {
    width: 33%;
    padding: 5px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 576px) {
  .grid-sizer, .grid-item {
    width: 50%;
    padding: 5px;
  }
}

@media only screen and (max-width: 575px) {
  .grid-sizer, .grid-item {
    width: 100%;
    padding: 5px;
  }
}

