.black-header-gradient {
  background-image: linear-gradient(5deg, #555555 0%, #101010 74%);
  /* border-radius: 0px 0px 100px 100px; */
  /* background-image: linear-gradient(335deg, #000000 0%, #181818 50%, #202020 50%, #414141 100%); */
}

.black-anthracite-gradient {
  background: linear-gradient(0deg, rgba(60, 60, 60, 1) 0%, rgba(32, 32, 32, 1) 50%);
}

.section-title-white {
  color: white;
  font-size: 36px;
  font-weight: 400;
  text-align: center;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 800;
}

.semi-bold-white {
  color: white;
  font-weight: 600;
}

.padding-100 {
  padding-left: 200px !important;
  padding-right: 200px !important;
}

.small-text {
  font-size: 16px;
  padding-left: 70px;
  padding-right: 70px;
  text-align: left !important;
}

.material-card-turquois {
  border: none !important;
  background: linear-gradient(45deg, #89d8d3 0%, #03c8a8 50%) !important;
}

.section-separator {
  height: 120px;
}

.rounded-turquoise-button {
  background-image: linear-gradient(45deg, #89d8d3 0%, #03c8a8 50%);
  border-radius: 20px 20px 20px 20px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.gray-hover-transition:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.gray-hover-transition {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.frosted {
  height: 200px;
  width: 400px;
  background: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 9px;
  overflow: hidden;
}

.frosted::before {
  content: "";
  height: 200px;
  width: 400px;
  position: absolute;
  top: -10px;
  bottom: -10px;
  right: -10px;
  left: -10px;
  border-radius: 9px;
  background: inherit;
  box-shadow: inset 0 0 200px rgba(255, 255, 255, 0.5);
  filter: blur(12px) !important;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.smaller-mobile {
  display: block;
}

.about-us {
  padding-top: 0px;
}

.footer-image {
  display: block;
}

@media (min-width: 1461px) {
  .huge-title {
    font-size: 150px;
    font-weight: 500;
    text-align: center;
  }
}

@media (min-width: 801px) and (max-width: 1460px) {
  .huge-title {
    font-size: 100px;
    font-weight: 500;
    text-align: center;
  }
}

@media (max-width: 800px) {
  .smaller-mobile {
    max-width: 50%;
  }

  .about-us {
    padding-top: 25px !important;
  }

  .huge-title {
    font-size: 50px !important;
    font-weight: 500;
    text-align: center;
  }

  .footer-image {
    display: none;
  }
}


@media (min-width: 1000px) and (max-width:1199px) {
  .margin-nav-button {
    margin-left: 300px !important;
    margin-right: 300px !important;
  }
  .margin-top-button {
    margin-top: 5px !important;
  }
}

@media (min-width: 800px) and (max-width:1000px) {
  .margin-nav-button {
    margin-left: 200px !important;
    margin-right: 200px !important;
  }
  .margin-top-button {
    margin-top: 5px !important;
  }
}

@media (max-width:799px) {
  .margin-top-button {
    margin-top: 5px !important;
  }
}

@media (max-width:520px) {
  .small-font-mobile {
    font-size: 12px !important;
  }
}

@media (min-width:1200px) and (max-width: 1465px){
  .small-font-mobile {
    font-size: 12px !important;
  }
}



