body {
    margin: 0;
    font-family: Poppins !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #202020 !important;
    transition: background 500ms ease-in-out, color 1000ms ease-in-out;
    -webkit-transition: background 500ms ease-in-out, color 1000ms ease-in-out;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.modal-content {
    background: #EFEFF4 !important;
}

